/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable import/no-named-as-default */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react'
import Slider from 'rc-slider'
import CheckboxGroup from 'react-checkbox-group'
import Modal from 'react-modal'
import DataContext from '../components/Common/DataContext'

import IconLink from '../components/Common/IconLink'
import IconButton from '../components/Common/IconButton'
import { ColorLink, ColorButton } from '../components/Common/Buttons'
import IndexLayout from '../layouts'
import CultureIcon from '../assets/heart.inline.svg'
import HiringIcon from '../assets/team.inline.svg'
import BalanceIcon from '../assets/smile.inline.svg'
import EmpowermentIcon from '../assets/rocket.inline.svg'
import CheckIcon from '../assets/check-circle.inline.svg'

import {
  SurveyWrapper,
  SurveyInner,
  Title,
  Subtitle,
  TitleWrapper,
  ColorWrapper,
  BackWrapper,
  SliderLabel,
  Slide,
  SliderWrapper,
  SliderLabelWrapper,
  trackStyle,
  railStyle,
  handleStyle,
  SurveyItem,
  GroupWrapper,
  IconWrapper,
  CardTitle,
  InnerWrapper
} from '../style/survey-style'

const customStyles = {
  content: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    background: 'linear-gradient(180deg, #27202A 0%, #0C080E 100%)',
    border: 'none',
    borderRadius: 0
  },
  overlay: { zIndex: 100 }
}

const TopicFilter = [
  {
    value: 'culture',
    label: 'Company culture',
    icon: <CultureIcon />
  },
  {
    value: 'hiring',
    label: 'Hiring',
    icon: <HiringIcon />
  },
  {
    value: 'balance',
    label: 'Work-life Balance',
    icon: <BalanceIcon />
  },
  {
    value: 'empowerment',
    label: 'Employee Empowerment',
    icon: <EmpowermentIcon />
  }
]

Modal.setAppElement('#___gatsby')
const surveyLabel = ['', 'low', 'moderate', 'high']

const Survey = () => {
  const contextValue = useContext(DataContext)
  const { step, handleStep, topicsParam, handleTopicsParam, globalResource, handleGlobalResource } = contextValue

  return (
    <IndexLayout>
      <Modal isOpen style={customStyles} contentLabel="Survey">
        <SurveyWrapper>
          <BackWrapper>{step === 0 ? <IconLink to="/" label="Back" /> : <IconButton onClick={() => handleStep(0)} />}</BackWrapper>
          <SurveyInner>
            <TitleWrapper>
              <Title>{step === 0 ? 'What areas do you want to improve?' : 'What level of resources are you ready to invest?'}</Title>
            </TitleWrapper>
            <Subtitle>{step === 0 ? 'You can select multiple areas' : 'i.e. time, budget and people'}</Subtitle>

            <GroupWrapper>
              {step === 0 ? (
                <CheckboxGroup name="topic-parameters" value={topicsParam} onChange={values => handleTopicsParam(values)}>
                  {Checkbox => (
                    <>
                      {TopicFilter.map(elem => {
                        // @ts-ignore
                        const isSelected = topicsParam.includes(elem.value)
                        return (
                          <SurveyItem key={`survey_topic_${elem.label}`}>
                            <Checkbox value={elem.value} />
                            <div className="inco-survey-checkbox-fake-input">
                              <CheckIcon className="inco-survey-checkbox-check-icon" />
                              <InnerWrapper>
                                <IconWrapper isSelected={isSelected}>{elem.icon}</IconWrapper>
                                <CardTitle>{elem.label}</CardTitle>
                              </InnerWrapper>
                            </div>
                          </SurveyItem>
                        )
                      })}
                    </>
                  )}
                </CheckboxGroup>
              ) : (
                <SliderWrapper>
                  <SliderLabelWrapper globalResource={globalResource}>
                    <SliderLabel>{surveyLabel[globalResource]}</SliderLabel>
                  </SliderLabelWrapper>
                  <Slide>
                    {/* @ts-ignore */}
                    <Slider
                      min={1}
                      max={3}
                      step={1}
                      value={globalResource}
                      onChange={(value: number) => handleGlobalResource(value)}
                      railStyle={railStyle}
                      trackStyle={trackStyle}
                      handleStyle={handleStyle}
                    />
                  </Slide>
                </SliderWrapper>
              )}
            </GroupWrapper>
            <ColorWrapper>
              {step === 0 ? (
                <ColorButton type="button" onClick={() => handleStep(1)} disabled={topicsParam.length === 0}>
                  Continue
                </ColorButton>
              ) : (
                <ColorLink to="/recommendations" onClick={() => handleStep(2)}>
                  Continue
                </ColorLink>
              )}
            </ColorWrapper>
          </SurveyInner>
        </SurveyWrapper>
      </Modal>
    </IndexLayout>
  )
}

export default Survey
