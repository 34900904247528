import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import Arrow from '../../assets/arrow.inline.svg'

const IconLinkWrapper = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 1px;
  text-transform: uppercase;
  height: 26px;
`

const Label = styled.div`
  padding-left: 15px;
`

interface IconLinkProps {
  to: string
  label?: string
}

const IconLink: React.FC<IconLinkProps> = ({ to, label = 'Back' }) => (
  <IconLinkWrapper to={to}>
    <Arrow />
    <Label>{label}</Label>
  </IconLinkWrapper>
)

export default IconLink
