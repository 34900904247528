import React from 'react'
import styled from '@emotion/styled'
import Arrow from '../../assets/arrow.inline.svg'

const IconLinkWrapper = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fbfafc;
  background-color: transparent;
  border: none;
  padding-left: 0;
  cursor: pointer;
  height: 26px;
`

const Label = styled.div`
  padding-left: 15px;
`

interface IconLinkProps {
  onClick: () => void
  label?: string
}

const IconLink: React.FC<IconLinkProps> = ({ onClick, label = 'Back' }) => (
  <IconLinkWrapper onClick={onClick}>
    <Arrow />
    <Label>{label}</Label>
  </IconLinkWrapper>
)

export default IconLink
