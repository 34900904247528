import styled from '@emotion/styled'
import { colors } from '../styles/variables'

export const SurveyWrapper = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 72px;
`

export const GroupWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 58px;
  padding-top: 42px;
  @media (max-width: 850px) {
    flex-direction: column;
  }
`

export const IconWrapper = styled.div`
  padding: 20px;
  border-radius: 35px;
  display: flex;
  height: 64px;
  width: 64px;
  background: ${({ isSelected }: { isSelected: boolean }) =>
    isSelected ? 'linear-gradient(180deg, #db767c 0.01%, #a633c3 100%)' : colors.gradient.nuances3};
`

export const CardTitle = styled.div`
  height: 54px;
  display: flex;
  align-items: center;
  margin-top: 24px;
  font-size: 18px;
  @media (max-width: 850px) {
    margin-top: 0;
    padding-left: 24px;
    text-align: left;
  }
`

export const SurveyInner = styled.div`
  max-width: 900px;
`
export const BackWrapper = styled.div`
  align-self: flex-start;
`

export const Title = styled.h2`
  max-width: 380px;
  padding-top: 40px;
  padding-bottom: 8px;
`

export const TitleWrapper = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
`
export const Subtitle = styled.p`
  color: ${colors.plain.nuances7};
  font-size: 18px;
  text-align: center;
`

export const ColorWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const SliderWrapper = styled.div`
  padding: 12px 0 40px 0;
  width: 432px;
  @media (max-width: 850px) {
    width: 100%;
  }
`

const labelAlignment = ['', 'flex-start', 'center', 'flex-end']

export const SliderLabelWrapper = styled.div`
  padding-top: 16px;
  display: flex;
  justify-content: ${({ globalResource }: { globalResource: number }) => labelAlignment[globalResource]};
`

export const Slide = styled.div`
  padding: 36px 24px 0 24px;
`

export const SliderLabel = styled.div`
  background: ${colors.gradient.nuances2};
  border-radius: 6px;
  padding: 12px 24px;
  display: inline-block;
`

export const railStyle = {
  background: colors.gradient.nuances2,
  borderRadius: 8,
  height: 8
}

export const trackStyle = {
  background: colors.gradient.nuances10,
  borderRadius: 8,
  height: 8
}

export const handleStyle = {
  background: colors.gradient.nuances10,
  width: 24,
  height: 24,
  boxShadow: '0px 4px 12px rgba(11, 1, 11, 0.5)',
  marginTop: '-9px',
  border: 'none'
}

export const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (max-width: 850px) {
    flex-direction: row;
    align-self: flex-start;
  }
`

export const SurveyItem = styled.label`
  cursor: pointer;
  font-family: Source Sans Pro;
  display: flex;
  align-items: center;
  width: 192px;
  height: 206px;
  font-weight: 600;
  font-size: 18px;
  color: ${colors.plain.nuances9};
  margin: 0 12px;
  @media (max-width: 850px) {
    width: 100%;
    height: 128px;
    margin: 12px 0;
  }
  /* &:hover {
    @media (min-width: 900px) {
      transition: 200ms;
      transform: scale(1.03);
    }
  } */
  & input {
    opacity: 0;
    z-index: 10;
    @media (max-width: 850px) {
      height: 128px;
    }
    height: 206px;
    position: absolute;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

  .inco-survey-checkbox-fake-input {
    width: 192px;
    height: 206px;
    padding: 32px;
    border-radius: 12px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: ${colors.gradient.nuances2};
    flex-direction: column;
    @media (max-width: 850px) {
      width: 100%;
      height: 128px;
    }
    .inco-survey-checkbox-check-icon {
      position: absolute;
      right: 14px;
      top: 14px;
      opacity: 0;
    }
  }

  input:checked + .inco-survey-checkbox-fake-input {
    border: 2px solid ${colors.plain.nuances9};
    padding: 30px;
    .inco-survey-checkbox-check-icon {
      position: absolute;
      right: 12px;
      top: 12px;
      opacity: 1;
    }
  }

  .inco-checkbox-container-disabled {
    display: flex;
    width: 100%;

    & .inco-checkbox-hidden-input {
      cursor: not-allowed;
    }

    & .inco-checkbox-displayed-fake-input {
      background-color: ${colors.plain.nuances7};
      border-color: ${colors.plain.nuances7};
    }
  }
`
